(function () {
  const $collapseEl = document.querySelector('#navbar-collapse-basic');
  const $scrollSpyEl = document.querySelector('[data-hs-scrollspy="#scrollspy"]');
  $scrollSpyEl.addEventListener('scroll.hs.scrollspy', () => {
    if (window.outerWidth <= 639 && $collapseEl.classList.contains('open')) {
      HSCollapse.hide($collapseEl);
    }
  });

  let anchorSelector = 'main a[href^="#"]';
  let anchorList = document.querySelectorAll(anchorSelector);

  anchorList.forEach((link) => {
    link.onclick = function (e) {
      e.preventDefault();
      let destination = document.querySelector(this.hash);
      destination.scrollIntoView({
        behavior: 'smooth',
      });
    };
  });
})();
